import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';

import { Division } from '@carousel/nexus';

const baseUrl = import.meta.env.VITE_BFF_BASE_URL || 'https://bff.carousel.gg';

const fetchDivisionsByOrganizationId = createAsyncThunk(
  'adminDivision/fetchDivisionsByOrganizationId',
  async ({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<Division[]> => {
    const response = await axios.get(`${baseUrl}/division/${organizationId}/`);

    if (response.status !== 200) {
      throw new Error('Error fetching division by id');
    }
    return response.data as Division[];
  },
);

const fetchDivisionByOrganizationIdAndDivisionById = createAsyncThunk(
  'adminDivision/fetchDivisionByOrganizationIdAndDivisionById',
  async ({
    organizationId,
    divisionId,
  }: {
    organizationId: string;
    divisionId: string;
  }): Promise<Division> => {
    const response = await axios.get(
      `${baseUrl}/division/${organizationId}/${divisionId}`,
    );

    if (response.status !== 200) {
      throw new Error('Error fetching division by id');
    }
    return response.data as Division;
  },
);

const updateDivision = createAsyncThunk(
  'adminDivision/updateDivision',
  async ({
    organizationId,
    divisionId,
    updateDivisionRequestBody,
  }: {
    organizationId: string;
    divisionId: string;
    updateDivisionRequestBody: Partial<Division>;
  }): Promise<Division | null> => {
    if (!divisionId) {
      return null;
    }

    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.put(
      `${baseUrl}/division/${organizationId}/${divisionId}`,
      updateDivisionRequestBody,
      config,
    );

    if (response.status !== 200) {
      throw new Error('Error updating division');
    }
    return response.data as Division;
  },
);

const disableDivision = createAsyncThunk(
  'adminDivision/disableDivision',
  async ({
    organizationId,
    divisionId,
  }: {
    organizationId: string;
    divisionId: string;
  }): Promise<Division> => {
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.put(
      `${baseUrl}/division/${organizationId}/${divisionId}/disable`,
      config,
    );

    if (response.status !== 200) {
      throw new Error('Error disabling division');
    }
    return response.data as Division;
  },
);

const enableDivision = createAsyncThunk(
  'adminDivision/enableDivision',
  async ({
    organizationId,
    divisionId,
  }: {
    organizationId: string;
    divisionId: string;
  }): Promise<Division> => {
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.put(
      `${baseUrl}/division/${organizationId}/${divisionId}/enable`,
      config,
    );

    if (response.status !== 200) {
      throw new Error('Error enabling division');
    }
    return response.data as Division;
  },
);

const createDivision = createAsyncThunk(
  'adminDivision/createDivision',
  async ({
    organizationId,
    createDivisionRequestBody,
  }: {
    organizationId: string;
    createDivisionRequestBody: Partial<Division>;
  }): Promise<Division> => {
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.post(
      `${baseUrl}/division/${organizationId}`,
      createDivisionRequestBody,
      config,
    );

    if (response.status !== 201) {
      throw new Error('Error creating division');
    }
    return response.data as Division;
  },
);

export {
  fetchDivisionsByOrganizationId,
  fetchDivisionByOrganizationIdAndDivisionById,
  createDivision,
  enableDivision,
  disableDivision,
  updateDivision,
};
